import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import AdUnit from './AdUnit';
import { SELECTOR } from './constants';
import store from '../store/configure';
import select from '../utils/select';

export default function buildAdUnit({
  selectorSuffix,
  slot,
  collapseOnFail,
  isInPageSource,
  display,
}) {
  const attachEl = select(`#${SELECTOR}-${selectorSuffix}`);

  render(
    <Provider store={store}>
      <AdUnit
        slot={slot}
        display={display}
        isInPageSource={isInPageSource}
        collapseOnFail={collapseOnFail}
      />
    </Provider>,
    attachEl
  );
}
