import { PENDING_TYPE, SUCCESS_TYPE, ERROR_TYPE } from './types';

const initialState = { isPending: true, didError: false };

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PENDING_TYPE:
      return {
        ...state,
        isPending: true,
        didError: false,
      };
    case SUCCESS_TYPE:
      return {
        ...state,
        isPending: false,
        didError: false,
      };
    case ERROR_TYPE:
      return {
        ...state,
        isPending: false,
        didError: true,
      };
    default:
      return state;
  }
};

export default reducer;
