import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import getRandomNum from '../../utils/get-random-num';
import selectAll from '../../utils/select-all';
import { MAPPINGS, ALL_SELECTOR } from '../constants';

window.googletag = window.googletag || { cmd: [] };

const pageSourceUnitIds = [];
const clientSideUnitIds = [];
const numPageSourceUnits = selectAll(`.${ALL_SELECTOR}`).length;
let displayedInitialUnits = false;

function getTargets() {
  const targets = [
    {
      key: 'pageURL',
      value: window.location.pathname.slice(0, 40),
    },
    {
      key: 'subdomain',
      value: 'www',
    },
  ];

  if (window.ttGlobal.pageType === 'article') {
    const { series, contentId, gutenTags, authors } = window.ttGlobal;

    targets.push({ key: 'articleId', value: String(contentId) });
    targets.push({ key: 'guten', value: gutenTags });
    targets.push({ key: 'series', value: series });
    targets.push({ key: 'authors', value: authors });
  }
  if (window.ttGlobal.pageType === 'series') {
    const { series, contentId } = window.ttGlobal;

    targets.push({ key: 'seriesId', value: String(contentId) });
    targets.push({ key: 'series', value: series });
  }

  return targets;
}

function setOptions() {
  const { googletag } = window;

  googletag.cmd.push(() => {
    googletag.pubads().enableLazyLoad({
      fetchMarginPercent: 80,
      renderMarginPercent: 80,
      mobileScaling: 1.3,
    });

    googletag.enableServices();
  });
}

function displayUnit(id) {
  window.googletag.display(id);
}

function displayUnits() {
  const unitsIds = [...pageSourceUnitIds, ...clientSideUnitIds];

  window.googletag.cmd.push(() => {
    unitsIds.forEach((id) => {
      displayUnit(id);
    });
  });
}

/**
 * Determines what 'thank you' message to display with an ad.
 *
 * @param {Object} storyData - The story data object.
 * @returns {Object} - The ad message object.
 */
const getAdMessage = (storyData) => {
  const series = storyData.find(({ key }) => key === 'series');
  // default ad message
  let adMessage = {
    text: 'The Texas Tribune thanks its sponsors.',
    link: 'https://mediakit.texastribune.org/',
  };
  if (series) {
    const isTribFest = series.value.some((item) =>
      item.includes('texas-tribune-festival')
    );
    if (isTribFest) {
      adMessage = {
        text: 'The Texas Tribune thanks its Festival sponsors.',
        link: 'https://festival.texastribune.org',
      };
    }
  }
  return adMessage;
};

export default function useAd({ slot, isInPageSource, collapseOnFail }) {
  const adIdRef = useRef(`_${getRandomNum(10000)}`);
  const [adLoadEvent, setAdLoadEvent] = useState(null);
  const adsPrepareDidError = useSelector(
    ({ adsPrepare }) => adsPrepare.didError
  );
  const targets = getTargets();
  const adMessage = getAdMessage(targets);

  useEffect(() => {
    const { googletag } = window;

    googletag.cmd.push(() => {
      const id = adIdRef.current;
      const { name, size, sizeMapping } = MAPPINGS[slot];
      const dfpSlot = googletag.defineSlot(name, size, id);

      getTargets().forEach(({ key, value }) => {
        dfpSlot.setTargeting(key, value);
      });

      dfpSlot.defineSizeMapping(sizeMapping).addService(googletag.pubads());

      googletag.pubads().addEventListener('slotRenderEnded', (event) => {
        if (event.slot === dfpSlot) {
          setAdLoadEvent(event);
        }
      });

      if (displayedInitialUnits) {
        displayUnit(id);
      } else {
        if (isInPageSource && !pageSourceUnitIds.includes(id)) {
          pageSourceUnitIds.push(id);
        } else if (!isInPageSource && !clientSideUnitIds.includes(id)) {
          clientSideUnitIds.push(id);
        }

        if (pageSourceUnitIds.length === numPageSourceUnits) {
          displayedInitialUnits = true;
          setOptions();
          displayUnits();
        }
      }
    });
  }, [slot, isInPageSource]);

  const adCreativeFailed = adLoadEvent && adLoadEvent.isEmpty;
  const shouldRenderAd = !(
    collapseOnFail &&
    (adCreativeFailed || adsPrepareDidError)
  );

  return {
    adIdRef,
    adLoadEvent,
    shouldRenderAd,
    adMessage,
  };
}
