export default function extractUser(authResult) {
  const keyMap = [
    ['email', 'email'],
    ['email_verified', 'emailVerified'],
    ['https://texastribune.org/is_staff', 'isStaff'],
  ];
  const user = {};

  keyMap.forEach(group => {
    user[group[1]] = authResult[group[0]];
  });

  return user;
}
