import { setPending, setSuccess, setError } from './index';
import loadScript from '../../utils/load-script';

export default function prepareAds() {
  return async dispatch => {
    dispatch(setPending());

    try {
      await loadScript({
        url: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
      });

      dispatch(setSuccess());
    } catch (err) {
      dispatch(setError());
    }
  };
}
