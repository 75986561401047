import thunk from 'redux-thunk';
import { createStore, applyMiddleware, combineReducers } from 'redux';

import userReducer from '../user/reducer';
import adsPrepareReducer from '../ads/reducer';

function createReducer(asyncReducers = {}) {
  const appReducer = combineReducers({
    user: userReducer,
    adsPrepare: adsPrepareReducer,
    ...asyncReducers,
  });

  const rootReducer = (state, action) => {
    // eslint-disable-next-line no-param-reassign
    if (action.type === 'RESET_STATE') state = undefined;
    return appReducer(state, action);
  };

  return rootReducer;
}

export function injectAsyncReducer(store, name, asyncReducer) {
  // eslint-disable-next-line no-param-reassign
  store.asyncReducers[name] = asyncReducer;
  store.replaceReducer(createReducer(store.asyncReducers));
}

const store = createStore(createReducer(), applyMiddleware(thunk));
store.asyncReducers = {};

export default store;
