export const STATIC_PATH = '/static/';

export const RESPONSIVE_VID_CLASS = 'js-video';

// eslint-disable-next-line prefer-destructuring
export const GOOGLE_PLACES_KEY = process.env.GOOGLE_PLACES_KEY;

export const ARTICLE_BASE = '/api/v2/articles/';

export const TRENDING_BASE = '/api/v2/articles/most_viewed/';

export const ARTICLE_FIELDS = [
  'id',
  'headline',
  'ap_pub_date',
  'sitewide_image',
  'ap_updated_date',
  'url',
];

export const RICH_ARTICLE_FIELDS = [
  'id',
  'headline',
  'ap_pub_date',
  'sitewide_image',
  'ap_updated_date',
  'url',

  'authors',
  'placeholders',
  'series',
  'tags',
];

export const SERIES_BASE = '/api/v2/series/';

export const SERIES_FIELDS = ['id', 'name', 'slug', 'summary'];

export const POLITICIAN_BASE = '/api/v2/politicians/';

export const POLITICIAN_FIELDS = ['id', 'slug', 'hometown'];

// https://republic.texastribune.org/api/v1/officeholders/
const CURRENT_CYCLE = 2023;
export const OFFICEHOLDER_BASE = `https://republic-api.texastribune.org/${CURRENT_CYCLE}/`;

export const RECAPTCHA_KEY = process.env.GOOGLE_RECAPTCHA_KEY_ID;

export const RECAPTCHA_ACTION_SIGNUP = 'NEWSLETTER_SIGNUP';

export const SUBSCRIBE_API_URL = '/api/v1/users/subscribe/';

export const NEWSLETTER_ERROR_FALLBACK_MSG =
  'We’re sorry. Your newsletter sign-up didn’t go through. If the problem persists, email us at community@texastribune.org for help.';

// eslint-disable-next-line
export const __TEST__ = process.env.NODE_ENV === 'test';
