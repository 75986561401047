/**
  Select all matching elements on the page.
  Uses `document.querySelectorAll`.
*/
export default function selectAll(el, context) {
  // if `el` is a Node or Window, return it in an Array
  if (el instanceof Node) return [el];

  return Array.prototype.slice.call(
    typeof el === 'string'
      ? (context || document).querySelectorAll(el)
      : el || []
  );
}
