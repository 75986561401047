export const LOGIN_REDIRECT_KEY = 'postLoginRedirect';
export const LOGOUT_REDIRECT_KEY = 'postLogoutRedirect';
export const LOGGED_IN_FLAG_KEY = 'isLoggedIn';
export const STAFF_CONNECTION = 'texastribune-org';
export const NON_STAFF_CONNECTION = 'Username-Password-Authentication';
export const AUTH_DOMAIN = process.env.AUTH0_DOMAIN;
export const AUTH_COMMENTS_CLIENT_ID = process.env.AUTH0_COMMENTS_CLIENT_ID;
export const AUTH_COMMENTS_LOGOUT_COMPLETE_URL = `${
  window.location.origin
}/accounts/logout/`;
export const AUTH_COMMENTS_LOGIN_COMPLETE_URL = `${
  window.location.origin
}/accounts/login/complete/`;
