import React from 'react';
import Thanks from './Thanks';

const Leaderboard = ({ adIdRef, adMessage }) => (
  <div className="c-ad-ldbd">
    <Thanks text={adMessage.text} link={adMessage.link} />
    <div
      id={adIdRef.current}
      data-testid="ldbd-ad"
      className="c-ad-ldbd__unit"
    />
  </div>
);

export default Leaderboard;
