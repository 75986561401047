import React from 'react';

const Thanks = ({ text, link }) => {
  return (
    <p className="t-links t-size-xxs t-align-center has-xs-btm-marg has-text-gray-dark">
      {text}{' '}
      <a href={link}>
        <strong>Become one</strong>
      </a>
      .
    </p>
  );
};

export default Thanks;
