const defaultObserverOptions = { root: null, rootMargin: '0px', threshold: 0 };

export default function bindScrollActions({
  elements,
  callback,
  doOnce = false,
  observerOptions = {},
}) {
  let count = elements.length;

  const observer = new IntersectionObserver(
    (entries, self) => {
      entries.forEach(entry => {
        const { target, isIntersecting } = entry;

        if (!doOnce) {
          callback(isIntersecting);
        }

        if (doOnce && isIntersecting) {
          callback();
          self.unobserve(target);
          count -= 1;

          if (count === 0) {
            self.disconnect();
          }
        }
      });
    },
    { ...defaultObserverOptions, ...observerOptions }
  );

  elements.forEach(element => {
    observer.observe(element);
  });

  return observer;
}
