import getQueryParam from './get-query-param';

const createUtmString = () => {
  const source = getQueryParam('utm_source');
  const medium = getQueryParam('utm_medium');
  const term = getQueryParam('utm_term');

  if (source === null && medium === null && term === null) {
    return null;
  }

  return `${source},${medium},${term}`;
};

export default createUtmString;
