import React from 'react';
import Thanks from './Thanks';

const Cube = ({ adIdRef, adMessage, display: { showMembershipPromo } }) => {
  if (showMembershipPromo) {
    return (
      <div className="c-ad-cube c-ad-cube--promo">
        <div
          id={adIdRef.current}
          data-testid="cube-ad"
          className="c-ad-cube__unit"
        >
          <div className="c-ad-cube__content">
            <p className="c-ad-cube__hed t-size-l">
              <strong>
                Be a citizen, <br />
                not a spectator.
              </strong>
            </p>
            <p className="c-ad-cube__para t-size-s">
              Support independent, fact-based journalism.
            </p>
            <p className="c-ad-cube__para t-size-s">
              Donate to our <strong>nonprofit newsroom</strong> today by
              visiting{' '}
              <a
                className="c-ad-cube__link"
                href="https://support.texastribune.org/donate"
              >
                <strong>texastribune.org/donate</strong>
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="c-ad-cube c-ad-cube--basic">
      <Thanks text={adMessage.text} link={adMessage.link} />
      <div
        id={adIdRef.current}
        data-testid="cube-ad"
        className="c-ad-cube__unit"
      />
    </div>
  );
};

export default Cube;
