import React from 'react';
import Thanks from './Thanks';

const Flex = ({ adIdRef, adMessage }) => (
  <div className="c-ad-flex">
    <div className="c-ad-flex__inner">
      <Thanks text={adMessage.text} link={adMessage.link} />
      <div
        id={adIdRef.current}
        data-testid="flex-ad"
        className="c-ad-flex__unit"
      />
    </div>
  </div>
);

export default Flex;
