import auth from '../utils/auth';
import extractUser from '../utils/extract-user';
import setFlag from '../utils/set-flag';
import clearFlag from '../utils/clear-flag';
import { LOGGED_IN_FLAG_KEY } from '../constants';
import { setRequest, setLoggedIn, setLoggedOut, setError } from './index';

const isLoggedIn = () => localStorage.getItem(LOGGED_IN_FLAG_KEY) === 'true';

/*
  iOS Safari 10 and below don't support local storage in
  private windows. So if we're on one of those browsers,
  we bypass local storage entirely and use checkSession's
  `error: login_required` to determine whether someone is
  logged out.
*/

const isBadiOS = () => {
  const item = 'test';
  try {
    localStorage.setItem(item, item);
    localStorage.removeItem(item);
    return false;
  } catch (e) {
    return true;
  }
};

function badiOSCheck(dispatch) {
  return new Promise(resolve => {
    auth.checkSession({ responseType: 'id_token' }, (err, authResult) => {
      if (err) {
        if (err.error === 'login_required') {
          dispatch(setLoggedOut());
        } else {
          dispatch(setError());
        }
        resolve();
      } else {
        const user = extractUser(authResult.idTokenPayload);
        dispatch(
          setLoggedIn({
            user,
            token: authResult.idToken,
          })
        );
        resolve();
      }
    });
  });
}

function allOthersCheck(dispatch) {
  return new Promise(resolve => {
    // isLoggedIn flag is set in local storage
    if (isLoggedIn()) {
      auth.checkSession({ responseType: 'id_token' }, (err, authResult) => {
        const isValidResult = authResult && authResult.idToken;

        if (err) {
          // local-storage flag is set, but Auth0 session has expired
          if (err.error === 'login_required') {
            dispatch(setLoggedOut());
          } else {
            dispatch(setError()); // unexpected Auth0 error
          }
          clearFlag();
          resolve();
        } else if (!isValidResult) {
          // something is off about the Auth0 response
          dispatch(setError());
          clearFlag();
          resolve();
        } else {
          // success! format the returned user data
          const user = extractUser(authResult.idTokenPayload);
          dispatch(
            setLoggedIn({
              user,
              token: authResult.idToken,
            })
          );
          setFlag();
          resolve();
        }
      });
    } else {
      // isLoggedIn flag not set in local storage
      dispatch(setLoggedOut());
      clearFlag();
      resolve();
    }
  });
}

export default function loadUser() {
  return dispatch => {
    dispatch(setRequest());

    if (isBadiOS()) {
      return badiOSCheck(dispatch);
    }
    return allOthersCheck(dispatch);
  };
}
