import { LOGOUT_REDIRECT_KEY, LOGGED_IN_FLAG_KEY } from '../constants';

export default function redirectAfterLogOut() {
  const url = localStorage.getItem(LOGOUT_REDIRECT_KEY) || '/';

  try {
    localStorage.removeItem(LOGGED_IN_FLAG_KEY);
  } catch (err) {
    // temporary for iOS 10, which does not support
    // web storage in private windows
  }

  setTimeout(() => {
    window.location.href = url;
  }, 750);
}
