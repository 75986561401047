const getQueryParam = toFind => {
  const queries = window.location.search;

  if (!queries) return null;

  const queryPairs = queries.slice(1).split('&');
  const queryMap = {};

  queryPairs.forEach(pair => {
    const [key, value] = pair.split('=');

    if (!value) queryMap[key] = '';
    else queryMap[key] = value;
  });

  if (typeof queryMap[toFind] === 'undefined') return null;
  return queryMap[toFind];
};

export default getQueryParam;
