import * as types from './types';

const { REQUEST_TYPE, LOGGED_IN_TYPE, LOGGED_OUT_TYPE, ERROR_TYPE } = types;

const initialState = {
  isPending: true,
  didError: false,
  details: {
    email: '',
    emailVerified: false,
    isStaff: false,
  },
  token: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_TYPE:
      return {
        ...state,
        isPending: true,
      };
    case LOGGED_IN_TYPE:
      return {
        ...initialState,
        isPending: false,
        details: action.user,
        token: action.token,
      };
    case LOGGED_OUT_TYPE:
      return {
        ...initialState,
        isPending: false,
      };
    case ERROR_TYPE:
      return {
        ...initialState,
        isPending: false,
        didError: true,
      };
    default:
      return state;
  }
};

export default reducer;
