import * as types from '../types';

const { REQUEST_TYPE, LOGGED_IN_TYPE, LOGGED_OUT_TYPE, ERROR_TYPE } = types;

const setError = () => ({ type: ERROR_TYPE });
const setRequest = () => ({ type: REQUEST_TYPE });
const setLoggedOut = () => ({ type: LOGGED_OUT_TYPE });
const setLoggedIn = payload => ({ ...payload, type: LOGGED_IN_TYPE });

export { setRequest, setLoggedIn, setLoggedOut, setError };
