import React from 'react';
import Thanks from './Thanks';

const LeaderboardThenCube = ({ adIdRef, adMessage, adLoadEvent }) => {
  const classes = ['c-ad-ldbd-then-cube'];

  // this unit can be a cube or leaderboard depending on the initial
  // screen width, so we append a class accordingly so we can do some
  // css show/hide magic
  if (adLoadEvent && !adLoadEvent.isEmpty) {
    const { size } = adLoadEvent;

    if (size[0] === 728) {
      classes.push('started-with-ldbd');
    } else if (size[0] === 300) {
      classes.push('started-with-cube');
    }
  }

  return (
    <div className={classes.join(' ')}>
      <Thanks text={adMessage.text} link={adMessage.link} />
      <div
        id={adIdRef.current}
        data-testid="ldbd-then-cube"
        className="c-ad-ldbd-then-cube__unit l-pos-rel"
      />
    </div>
  );
};

export default LeaderboardThenCube;
