/* eslint-disable no-undef */
import { RECAPTCHA_KEY } from '../constants';

/**
 * Async wrapper for grecaptcha.execute(). This ensures we can catch
 * errors thrown by grecaptcha.execute()
 *
 * Note that 'grecaptcha' is a global variable loaded by a script tag in
 * /templates/shadow_layouts/base.html
 *
 * @param {string} action - description of the action being performed by the user.
 *
 * @returns {Promise<string>} - promise that resolves with a recaptcha token
 */
const executeToken = async (action) =>
  grecaptcha.enterprise.execute(RECAPTCHA_KEY, {
    action,
  });

/**
 * Gets recaptcha token from Google using recaptcha API.
 *
 * Note that 'grecaptcha' is a global variable loaded by a script tag in
 * /templates/shadow_layouts/base.html
 *
 * @param {string} action - description of the action being performed by the user.
 *
 * @returns {Promise<string>} - promise that resolves with a recaptcha token
 * */
const getRecaptchaToken = (action = 'SUBMIT') =>
  new Promise((resolve, reject) => {
    if (typeof grecaptcha === 'undefined') {
      reject(
        new Error('grecaptcha is not defined - the script may not have loaded')
      );
    }
    grecaptcha.enterprise.ready(() =>
      executeToken(action)
        .then((token) => resolve(token))
        .catch((err) => reject(err))
    );
  });

export default getRecaptchaToken;
