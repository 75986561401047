export default function loadScript({ url, async = true }) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.src = url;
    script.async = async;

    document.body.appendChild(script);

    script.onload = () => {
      resolve();
    };

    script.onerror = () => {
      reject(new Error('Script failed to load'));
    };
  });
}
