import React from 'react';

import Flex from './components/Flex';
import Leaderboard from './components/Leaderboard';
import Fluid from './components/Fluid';
import LeaderboardThenCube from './components/LeaderboardThenCube';
import Cube from './components/Cube';
import useAd from './hooks/use-ad';

const displayDefaults = { showMembershipPromo: false };

const isCube = (slot) => {
  if (
    slot === 'defaultCube' ||
    slot === 'eventCube' ||
    slot === 'homeCubeA' ||
    slot === 'homeCubeB' ||
    slot === 'homeCubeC'
  ) {
    return true;
  }

  return false;
};

const isLeaderboardThenCube = (slot) => {
  if (slot === 'footer' || slot === 'banner') {
    return true;
  }

  return false;
};

const AdUnit = ({
  slot,
  collapseOnFail,
  isInPageSource = true,
  display = {},
}) => {
  const { adIdRef, adLoadEvent, shouldRenderAd, adMessage } = useAd({
    slot,
    isInPageSource,
    collapseOnFail,
  });

  if (!shouldRenderAd) return null;

  const combinedDisplay = { ...displayDefaults, ...display };
  const props = { adIdRef, adLoadEvent, display: combinedDisplay, adMessage };

  if (isCube(slot)) {
    return <Cube {...props} />;
  }
  if (isLeaderboardThenCube(slot)) {
    return <LeaderboardThenCube {...props} />;
  }
  if (slot === 'roofline') {
    return <Leaderboard {...props} />;
  }
  if (slot === 'first') {
    return <Flex {...props} />;
  }
  if (slot === 'flex') {
    return <Flex {...props} />;
  }
  if (slot === 'paidPost') {
    return <Fluid {...props} />;
  }
  return null;
};

export default AdUnit;
