const SLOT_PREFIX = '/5805113/';

const LEADERBOARD_THEN_CUBE_MAPPING = [
  [[780, 140], [728, 90]],
  [[0, 0], [300, 250]],
];

const LEADERBOARD_MAPPING = [[[780, 140], [728, 90]], [[0, 0], []]];

const CUBE_MAPPING = [[[0, 0], [300, 250]]];

const FLEX_MAPPING = [
  [[1020, 300], [[970, 250], [728, 90], [300, 250]]],
  [[780, 140], [[728, 90], [300, 250]]],
  [[0, 0], [300, 250]],
];

const FLUID_MAPPING = [[[0, 0], ['fluid']]];

export const MAPPINGS = {
  banner: {
    name: `${SLOT_PREFIX}TexasTribune_Site_Roofline1_ATF_Leaderboard_728x90`,
    size: [300, 250],
    sizeMapping: LEADERBOARD_THEN_CUBE_MAPPING,
  },

  footer: {
    name: `${SLOT_PREFIX}TexasTribune_Content_StoryLanding_BTF_Footer_Leaderboard_728x90`,
    size: [300, 250],
    sizeMapping: LEADERBOARD_THEN_CUBE_MAPPING,
  },

  roofline: {
    name: `${SLOT_PREFIX}TexasTribune_Site_Roofline1_ATF_Leaderboard_728x90`,
    size: [728, 90],
    sizeMapping: LEADERBOARD_MAPPING,
  },

  defaultCube: {
    name: `${SLOT_PREFIX}basic`,
    size: [300, 250],
    sizeMapping: CUBE_MAPPING,
  },

  eventCube: {
    name: `${SLOT_PREFIX}Event_300x250`,
    size: [300, 250],
    sizeMapping: CUBE_MAPPING,
  },

  homeCubeA: {
    name: `${SLOT_PREFIX}Home_MedRectA_300x250`,
    size: [300, 250],
    sizeMapping: CUBE_MAPPING,
  },

  homeCubeB: {
    name: `${SLOT_PREFIX}Home_MedRectB_300x250`,
    size: [300, 250],
    sizeMapping: CUBE_MAPPING,
  },

  homeCubeC: {
    name: `${SLOT_PREFIX}Home_MedRectC_300x250`,
    size: [300, 250],
    sizeMapping: CUBE_MAPPING,
  },

  first: {
    name: `${SLOT_PREFIX}InStory_First`,
    size: [300, 250],
    sizeMapping: FLEX_MAPPING
  },

  flex: {
    name: `${SLOT_PREFIX}InStory_Flexible`,
    size: [300, 250],
    sizeMapping: FLEX_MAPPING,
  },

  paidPost: {
    name: `${SLOT_PREFIX}PaidPost`,
    size: ['fluid'],
    sizeMapping: FLUID_MAPPING,
  },
};

export const SELECTOR = 'ad-unit';

export const ALL_SELECTOR = 'js-ad-unit';
