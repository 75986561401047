import axios from 'axios';

/**
  Creates a single instance of axios with Django-specific
  configuration. Now we can use it throughout our code
  without having to repeat this config.
*/
const config = {
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
};

const ajaxer = axios.create(config);

export default ajaxer;
