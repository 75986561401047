import lazySizes from 'lazysizes/lazysizes-umd';
import textBalancer from '@texastribune/text-balancer';
import reframe from 'reframe.js';
import store from './store/configure';
import prepareAds from './ads/actions/prepare-ads';
import loadUser from './user/actions/load-user';
import redirectAfterLogOut from './user/utils/redirect-after-logout';
import redirectAfterLogIn from './user/utils/redirect-after-login';
import buildAdUnit from './ads';
import select from './utils/select';
import bindScrollActions from './utils/scroll-actions';
import { RESPONSIVE_VID_CLASS } from './constants';
// sentry has been temporarily disabled, see comment below for more details
// import sentryInit from './utils/sentry-init';

lazySizes.cfg.init = false;

/**
 * We comment out the following code and the corresponding import in order to
 * disable Sentry frontend coverage. Uncomment this code to re-enable it.
 * See #PR4722 for more details.
 */
// if (
//   process.env.ENABLE_SENTRY &&
//   process.env.ENABLE_SENTRY.toLowerCase() === 'true'
// ) {
//   sentryInit();
// }

export { buildAdUnit, redirectAfterLogOut, redirectAfterLogIn };

export function fireEntryDispatches() {
  store.dispatch(loadUser());
  store.dispatch(prepareAds());
}

// prettier-ignore
export async function buildCopyLink(config) {
  const module = await import(/* webpackChunkName: "build-copy-link" */ './copy-link');
  module.default(config);
}

export function buildLazyImages(config = {}) {
  const mergedConfig = {
    ...lazySizes.cfg,
    lazyClass: 'js-lazy-image--target',
    loadingClass: 'js-lazy-image--loading',
    loadedClass: 'js-lazy-image--loaded',
    ...config,
  };

  Object.keys(mergedConfig).forEach((key) => {
    lazySizes.cfg[key] = mergedConfig[key];
  });

  lazySizes.init();
}

export async function addTextBalance() {
  // if browser supports text-wrap: balance, just use that
  if (window.CSS?.supports?.('text-wrap', 'balance')) {
    return;
  }
  const balanceClasses = '.js-balance';

  // ideally we want to wait until fonts have loaded
  if ('fonts' in document) {
    await document.fonts.ready;
    textBalancer.balanceText(balanceClasses);
  }
}

export function showBackToTop() {
  const container = '#back-to-top-container';
  const anchor = '#back-to-top-anchor';
  const button = '#back-to-top-btn';
  const hideClass = 'is-transparent';

  select(button).addEventListener('click', () => {
    window.scrollTo(0, 0);
  });

  const containerEl = select(container);
  const elements = [select(anchor)];
  const callback = (isIntersecting) => {
    if (isIntersecting) {
      containerEl.classList.add(hideClass);
    } else {
      containerEl.classList.remove(hideClass);
    }
  };

  bindScrollActions({ elements, callback });
}

export function makeVidsResponsive(selectors = [`.${RESPONSIVE_VID_CLASS}`]) {
  reframe(selectors.join());
}

export function initPrompt({
  identifier,
  mode,
  modeDisplay,
  styleDisplay,
  resetHours,
  previewMode,
  BANNER,
  MODAL,
}) {
  // Base variables/functions for prompt.
  const promptKey = 'prompt';
  const promptId = identifier;
  const promptContainer = document.querySelector('.c-prompt-container');
  const prompt = promptContainer.querySelector('.c-prompt');
  const promptEsc = prompt.querySelector('.c-prompt__esc');
  const isBanner = mode === BANNER;
  const isModal = mode === MODAL;
  const VERSION = 1;
  let data;

  const setData = (closedAt) => {
    if (previewMode) return; // Skip for preview mode.

    // Determine when Prompt should reappear.
    let reappearAt;
    if (closedAt) {
      if (resetHours === 0) {
        // Never reappear.
        reappearAt = null;
      } else {
        // Reappear at `closedAt + resetHours`.
        reappearAt = new Date(closedAt);
        reappearAt.setHours(reappearAt.getHours() + resetHours);
      }
    }

    // Save Prompt data to localStorage.
    localStorage.setItem(
      promptKey,
      JSON.stringify({
        ...data,
        [promptId]: {
          closedAt,
          reappearAt,
        },
      })
    );
  };

  const updateMaxHeight = () => {
    // Because banner heights are dynamic, and in order to achieve
    // the specific animation we want, we need to manually set the
    // max-height at runtime.
    if (isBanner) promptContainer.style.maxHeight = `${prompt.offsetHeight}px`;
  };

  const initAnimation = () => {
    promptContainer.classList.add('c-prompt-container--animated');
  };

  const closePrompt = () => {
    promptContainer.removeAttribute('open');
    promptContainer.setAttribute('aria-hidden', 'true');
    setData(new Date()); // Sets closedAt and reappearAt.
    window.dataLayer?.push({
      event: 'promptClose',
      gaAction: modeDisplay,
      gaCategory: styleDisplay,
      gaValue: promptId,
    });
  };

  const openPrompt = () => {
    promptContainer.setAttribute('open', '');
    promptContainer.setAttribute('aria-hidden', 'false');
    promptContainer.addEventListener('click', closePrompt);
    promptEsc.addEventListener('click', closePrompt);
    if (isBanner) {
      updateMaxHeight();
      initAnimation(); // This allows closing to animate.
      window.addEventListener('resize', updateMaxHeight);
      // Allows for dynamic height changes if content changes.
      new MutationObserver(updateMaxHeight).observe(prompt, {
        attributes: true,
        childList: true,
        subtree: true,
      });
    }
    window.dataLayer?.push({
      event: 'promptShow',
      gaAction: modeDisplay,
      gaCategory: styleDisplay,
      gaValue: promptId,
    });
  };

  const closeOnEsc = (event) => {
    if (event.key === 'Escape') {
      closePrompt();
      window.removeEventListener('keydown', closeOnEsc);
    }
  };

  // Wait to open Prompt until user scrolls.
  const openOnScroll = () => {
    if (window.scrollY > 100) {
      openPrompt();
      window.removeEventListener('scroll', openOnScroll);
      window.addEventListener('keydown', closeOnEsc);
    }
  };

  // "previewMode" opens Prompt regardless of local data.
  if (previewMode) {
    initAnimation();
    openPrompt();
  }
  // Display/Data Logic
  else {
    // Initialize localStorage data.
    try {
      data = JSON.parse(localStorage.getItem(promptKey)); // GET
    } catch {
      /* continue regardless of error */
    } // PARSE
    if (data?.version !== VERSION) data = { version: VERSION }; // VALIDATE
    const newPrompt = !(promptId in data);
    if (newPrompt) data[promptId] = {}; // INIT
    const promptData = data[promptId];
    localStorage.setItem(promptKey, JSON.stringify(data)); // SAVE

    // Animate if prompt is new or is a modal.
    const shouldAnimate = newPrompt || isModal;
    if (shouldAnimate) initAnimation();

    // Open if Prompt has not been closed or if reappearAt has passed.
    const hasNotBeenClosed = !promptData.closedAt;
    const reappearAtPassed =
      promptData.reappearAt && new Date(promptData.reappearAt) < new Date();
    if (hasNotBeenClosed || reappearAtPassed) {
      if (isModal) {
        // Open modal on scroll.
        window.addEventListener('scroll', openOnScroll);
      } else if (isBanner) {
        // Open banner after 1 second if shouldAnimate.
        // This produces a smoother animation.
        const timeout = shouldAnimate ? 1000 : 0;
        setTimeout(() => {
          openPrompt();
        }, timeout);
      }
    }
  }
}
